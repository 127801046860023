import "./index.css";

const lsKey = 'imgData'

const base64Image = decodeURIComponent(location.hash.substring(1)) || localStorage.getItem(lsKey)

// @ts-ignore
// window.s = base64Image

// strip the hash
if (location.hash !== "") {
  history.replaceState({}, "", location.href.replace(location.hash, ""));
}

// console.log(base64Image);

localStorage.setItem(lsKey, base64Image || '')

const img = document.getElementById('meme')

if (img instanceof HTMLElement) {
  img.style.backgroundImage = `url(${base64Image})`;
  img.style.visibility = 'visible'
}
